const qaConfig = {
    APP_ID: 'CA',
    ENV: 'qa',
    SHOWINITIALCOACHMARK: false,
    PI_URL: 'https://login-stg.pearson.com/v1/piapi-int/login/js/v2/session.js',
    PI_CLIENT_ID: 'nIbAA9cUpQgVevJLEtXkHJ2yFGHgjyZn',
    ID_INTEGRATION_JS: 'https://mydata-qa.pearson.com/analytics/assets/js/plaDashboardIntegration.min.js',
    PEARSON_ICON: '/content-analysis/p-icons-sprite-1.1.svg',
    HELP_WORKFLOW_URL_TOPIC: null,
    HELP_WORKFLOW_URL_STUDENT: null,
    PARENT_APP_URL: 'https://mydata-qa.pearson.com',
    PERFORMANCE_API_URL: '/content-analysis/performance',
    NOTIFICATIONS_EMAIL_URL: 'https://api-stage.pearson.com/notifications/events',
    API_URL: 'https://mydata-qa.pearson.com/contentanalysisapi/graphql',
    DEMO_API_URL: 'https://mydata-demo.gradebook-qa.pearsondev.tech/graphiql/api/ca-qa',
    NOTIFICATION_AUTH: 'jponvWWrxuZyl5qXGvqGUpQNlMvCxlKE',
    EMAIL_REPLY_ADDRESS: 'xl-no-reply@pearson.com',
    PROJECT_NAME: 'pep-pla',
    LPID: 'XL',
    LPSID: 'HED',
    ROLE: 'instructor',
    COURSE_IDS: [{ courseId: 'urn:xl:hed:course/1238789' }],
    COMPOSITE_APP_NAME: 'pla-ca',
    GTM_CONFIG: {
        ID: 'GTM-TV7LHW6',
        AUTH: '-Bz6OJOG6wXHdAfM1_5U6w',
        PREVIEW: 'env-3'
    }
};

const stgConfig = {
    APP_ID: 'CA',
    ENV: 'stg',
    SHOWINITIALCOACHMARK: false,
    PI_URL: 'https://login-stg.pearson.com/v1/piapi-int/login/js/v2/session.js',
    PI_CLIENT_ID: 'nIbAA9cUpQgVevJLEtXkHJ2yFGHgjyZn',
    ID_INTEGRATION_JS: 'https://mydata-ppe.pearson.com/analytics/assets/js/plaDashboardIntegration.min.js',
    PEARSON_ICON: '/content-analysis/p-icons-sprite-1.1.svg',
    HELP_WORKFLOW_URL_TOPIC: null,
    HELP_WORKFLOW_URL_STUDENT: null,
    PARENT_APP_URL: 'https://mydata-ppe.pearson.com',
    PERFORMANCE_API_URL: '/content-analysis/performance',
    NOTIFICATIONS_EMAIL_URL: 'https://api-stage.pearson.com/notifications/events',
    NOTIFICATION_AUTH: 'jponvWWrxuZyl5qXGvqGUpQNlMvCxlKE',
    API_URL: 'https://mydata-ppe.pearson.com/contentanalysisapi/graphql',
    DEMO_API_URL: 'https://mydata-demo.gradebook-stg.pearsondev.tech/graphiql/api/ca-stg',
    EMAIL_REPLY_ADDRESS: 'xl-no-reply@pearson.com',
    PROJECT_NAME: 'pep-pla',
    LPID: 'XL',
    LPSID: 'HED',
    ROLE: 'instructor',
    COURSE_IDS: [{ courseId: 'urn:xl:hed:course/1236531' }],
    COMPOSITE_APP_NAME: 'pla-ca',
    GTM_CONFIG: {
        ID: 'GTM-TV7LHW6',
        AUTH: '-Bz6OJOG6wXHdAfM1_5U6w',
        PREVIEW: 'env-3'
    }
};

const prdConfig = {
    APP_ID: 'CA',
    ENV: 'prd',
    SHOWINITIALCOACHMARK: false,
    PI_URL: 'https://login.pearson.com/v1/piapi/login/js/v2/session.js',
    PI_CLIENT_ID: 'i5ePBLpffNQx7A6x7CBjUJNxGd4wjJay',
    ID_INTEGRATION_JS: 'https://mydata.pearson.com/analytics/assets/js/plaDashboardIntegration.min.js',
    PEARSON_ICON: '/content-analysis/p-icons-sprite-1.1.svg',
    HELP_WORKFLOW_URL_TOPIC: null,
    HELP_WORKFLOW_URL_STUDENT: null,
    PARENT_APP_URL: 'https://mydata.pearson.com',
    PERFORMANCE_API_URL: '/content-analysis/performance',
    NOTIFICATIONS_EMAIL_URL: 'https://api.pearson.com/notifications/events',
    NOTIFICATION_AUTH: 'XYcKKugvbp1Ytw7eV8zjL1QjIjaY6twT',
    API_URL: 'https://mydata.pearson.com/contentanalysisapi/graphql',
    DEMO_API_URL: 'https://demo.mydata.pearson.com/graphiql/api/ca',
    EMAIL_REPLY_ADDRESS: 'xl-no-reply@pearson.com',
    PROJECT_NAME: 'pep-pla',
    LPID: 'XL',
    LPSID: 'HED',
    ROLE: 'instructor',
    COURSE_IDS: [{ courseId: 'urn:xl:hed:course/1236531' }],
    COMPOSITE_APP_NAME: 'pla-ca',
    GTM_CONFIG: {
        ID: 'GTM-TV7LHW6',
        AUTH: '6TXu5HSTtHdLHyTJwi7JVg',
        PREVIEW: 'env-1'
    }
};

const prfConfig = {
    APP_ID: 'CA',
    ENV: 'prf',
    SHOWINITIALCOACHMARK: false,
    PI_URL: 'https://pi-pqa.pearsoned.com/v1/piapi-pqa/login/js/v2/session.js',
    PI_CLIENT_ID: 'nIbAA9cUpQgVevJLEtXkHJ2yFGHgjyZn',
    ID_INTEGRATION_JS: 'https://mydata-perf.pearson.com/analytics/assets/js/plaDashboardIntegration.min.js',
    PEARSON_ICON: '/content-analysis/p-icons-sprite-1.1.svg',
    HELP_WORKFLOW_URL_TOPIC: null,
    HELP_WORKFLOW_URL_STUDENT: null,
    PARENT_APP_URL: 'https://mydata-perf.pearson.com',
    PERFORMANCE_API_URL: '/content-analysis/performance',
    NOTIFICATIONS_EMAIL_URL: '/content-analysis/simulate-email',
    NOTIFICATION_AUTH: '',
    API_URL: 'https://mydata-perf.pearson.com.tech/contentanalysisapi/graphql',
    DEMO_API_URL: '',
    EMAIL_REPLY_ADDRESS: 'xl-no-reply@pearson.com',
    PROJECT_NAME: 'pep-pla',
    LPID: 'XL',
    LPSID: 'HED',
    ROLE: 'instructor',
    COURSE_IDS: [{ courseId: 'urn:xl:hed:course/1236531' }],
    COMPOSITE_APP_NAME: 'pla-ca',
    GTM_CONFIG: {
        ID: 'GTM-TV7LHW6',
        AUTH: '-Bz6OJOG6wXHdAfM1_5U6w',
        PREVIEW: 'env-3'
    }
};

const getConfigByEnv = (env) => {
    if (env === 'stg') {
        return stgConfig;
    }
    if (env === 'prd') {
        return prdConfig;
    }
    if (env === 'prf') {
        return prfConfig;
    }
    return qaConfig;
};

const configs = {
    getConfigByEnv,
};

export default configs;
